import { useEffect, useState } from "react";
import { getSanityConfig } from "~/utils/sanityConfig";
import type { GroqStore, Subscription } from "@sanity/groq-store";
import type { QueryParams } from "~/types";

export type SubscriptionOptions = {
	params: QueryParams | null;
	// TODO: Type this to some sort of Document
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initialData: any;
	isPreview: boolean;
};

export function usePreviewSubscription(
	query: string | null,
	subscriptionOptions: SubscriptionOptions
) {
	const { params, initialData, isPreview } = subscriptionOptions;
	const [data, setData] = useState(initialData);

	useEffect(() => {
		if (!isPreview) {
			return;
		}

		let sub: Subscription | undefined;
		let store: GroqStore | undefined;

		async function createStore() {
			const { projectId, dataset } = getSanityConfig();

			if (query === null) {
				return;
			}

			const groqStoreUtil = await import("~/utils/groq-store");

			store = groqStoreUtil.groqStore({
				projectId,
				dataset,
				listen: true,
				overlayDrafts: true,
				documentLimit: 10000, // Optional limit on number of documents, to prevent using too much memory unexpectedly. Affects live preview
				EventSource: groqStoreUtil.SanityEventSource,
				token: ENV.SANITY_SECRET,
			});

			store.subscribe(
				query,
				params ?? {}, // Params
				(err, result) => {
					if (err) {
						console.error("Oh no, an error:", err);
						return;
					}
					setData(result);
				}
			);
		}

		if (!store) {
			createStore();
		}

		return () => {
			if (sub?.unsubscribe()) sub.unsubscribe();
			if (store) store.close();
		};
	}, [isPreview, params, query]);

	return { data };
}
