import React from "react";
import { ClientOnly } from "remix-utils/client-only";
import { usePreviewSubscription } from "~/hooks/usePreviewSubscription";
import type { QueryParams } from "~/types";
import type { PageDocumentBase } from "~/types/sanity-schema";
import { filterDataToSingleItem } from "~/utils/sanity-helpers";

const PreviewQRCode = React.lazy(() => import("~/components/preview-qr-code"));
interface Props {
	query: string | null;
	queryParams: QueryParams | null;
	initialData: PageDocumentBase | undefined;
	isPreview: boolean;
	children: (data: PageDocumentBase) => React.ReactNode;
}

export default function Preview({
	query,
	queryParams,
	initialData,
	isPreview,
	children,
}: Props) {
	const { data: previewData } = usePreviewSubscription(query, {
		params: queryParams,
		initialData,
		isPreview,
	});

	const currentData = isPreview
		? filterDataToSingleItem(previewData, true)
		: initialData;

	return (
		<>
			{isPreview ? (
				<>
					<div className="fixed left-0 right-0 top-0 z-[999] bg-success p-1 text-center text-white opacity-60">
						Preview Mode
					</div>
					<ClientOnly>
						{() => (
							<React.Suspense fallback={null}>
								<PreviewQRCode />
							</React.Suspense>
						)}
					</ClientOnly>
				</>
			) : null}
			{currentData ? children(currentData) : null}
		</>
	);
}
